import {inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private document = inject(DOCUMENT);

  getCurrentUrl(): string {
    return this.document.location.href;
  }
}
