import {Routes} from '@angular/router';
import {RoutesConstants} from '@core/constants/routes.constants';
import {authOrAnonymousGuard} from '@core/guards/auth-or-anonymous.guard';

const supportedLanguages = ['', 'en']; // Empty string for default language

// Define base routes without languages
const baseRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@pages/home/home.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.CATEGORY.path,
    loadChildren: () => import('@pages/categories/categories.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.COLLECTIONS.path,
    loadChildren: () => import('@pages/collections/collections.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.PROPOSAL.path,
    loadChildren: () => import('@pages/proposals/proposal.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.PRODUCT.path,
    loadChildren: () => import('@pages/product/product.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.PROFILE.path,
    loadChildren: () => import('@pages/profile/profile.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.SEARCH.path,
    loadChildren: () => import('@pages/search/search.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.BASKET.path,
    canActivate: [authOrAnonymousGuard],
    loadChildren: () => import('@pages/basket/basket.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.CHECKOUT.path,
    canActivate: [authOrAnonymousGuard],
    loadChildren: () => import('@pages/checkout/checkout.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.INFO.path,
    loadChildren: () => import('@pages/info-pages/info-pages.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.BRAND.path,
    loadChildren: () => import('@pages/company/company.routes').then(r => r.routes),
  },
  {
    path: RoutesConstants.ERRORS.path,
    loadChildren: () => import('@pages/errors/errors.routing').then(r => r.routes),
  },
  {
    path: '**',
    loadComponent: () => import('@pages/errors/not-fount/not-fount.component').then(c => c.NotFountComponent),
  },
];


const generateLanguageRoutes = (baseRoutes: Routes): Routes => {
  const routes: Routes = [];
  baseRoutes.forEach(route => {
    supportedLanguages.forEach(language => {
      const newRoute = { ...route };

      // Add language prefix to path if needed
      if (language) {
        newRoute.path = language + (newRoute.path ? '/' + newRoute.path : '');
      }

      // Push the new route to the array
      routes.push(newRoute);
    })
  })

  return routes;
};

// Apply the language generator
export const routes: Routes = generateLanguageRoutes(baseRoutes);
