import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {Colors} from '@core/constants/colors.constants';
import {IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {ButtonType} from '@shared/components/ui/buttons/button/button.component';
import {BasketService} from '@shared/services/api/basket.service';
import {WishlistService} from '@shared/services/api/wishlist.service';
import {SeoService} from '@shared/services/seo.service';
import {Values} from '@core/constants/values.constants';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  private document = inject(DOCUMENT);
  private basketService = inject(BasketService);
  private wishlistService = inject(WishlistService);
  private seoService = inject(SeoService);

  ngOnInit() {
    this.initColors();
    this.basketService.init();
    this.wishlistService.init();
    this.seoService.init();
  }

  private initColors() {
    const root = this.document.documentElement;
    Object.entries(Colors).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value);
    });

    Object.entries(Values).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value + 'px');
    })
  }

  protected readonly IconSize = IconSize;
  protected readonly Colors = Colors;
  protected readonly ButtonType = ButtonType;
}
